import MDinput from '@/components/MDinput';
import TextArea from '@/components/DSE/TextArea';
import DatePicker from '@/components/DSE/DatePicker';

export function getFormDefinition(vue) {
  return {
    occurredAt: {
      type: DatePicker,
      props: {
        disabled: true,
        caption: 'eventlog.occurredAt',
        type: 'datetime',
        format: 'dd-MM-yyyy HH:mm:ss'
      }
    },
    thread: {
      type: MDinput,
      props: {
        disabled: true,
        caption: 'eventlog.thread'
      }
    },
    level: {
      type: MDinput,
      props: {
        disabled: true,
        caption: 'eventlog.level'
      }
    },
    logger: {
      type: MDinput,
      props: {
        disabled: true,
        caption: 'eventlog.logger'
      }
    },
    message: {
      type: TextArea,
      props: {
        disabled: true,
        caption: 'eventlog.message',
        placeholder: 'eventlog.emptyMessage'
      }
    },
    exception: {
      type: TextArea,
      props: {
        disabled: true,
        caption: 'eventlog.exception',
        placeholder: 'eventlog.emptyException'
      }
    }
  };
}
